
@import "base";
@import "index";

.margin-top-15 {
  margin-top: 15px;
}

.display-row {
  .col-md-3 {
    text-align: right;
    font-weight: bold;
  }
}

.small-input {
  width: 80px !important;
}

.input-cell {
  width: 130px;
}

.input-group-addon.btn.btn-primary {
  height: 34px;
  text-align: center;
  padding: 6px 18px 6px 12px !important;
  background-color: #337ab7;
  color: #fff;
}

.off-canvas__container {
  position: relative;
}

.off-canvas__nav {
  background-color: #fff;
  border-right: 1px solid #000;
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  -webkit-user-drag: none;
}

.navbar.navbar-fixed-top {
  margin-top: -51px;
}